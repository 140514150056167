<template>
  <v-card elevation="0" :loading="loading" class="loan-details">
    <v-toolbar
      :color="$vuetify.theme.dark ? '' : 'grey lighten-3'"
      flat
      dense
    >
      <v-toolbar-title>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          show-arrows
        >
          <v-tab>
            <span v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-file-document-outline</v-icon></span>
            <span v-else>{{ $t('loanDetails.details') }}</span>
          </v-tab>
          <v-tab>
            <span v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-bank-transfer-out</v-icon></span>
            <span v-else>{{ $t('loanDetails.borrowerPayments') }}</span>
          </v-tab>
          <v-tab>
            <span v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-account-arrow-left</v-icon></span>
            <span v-else>{{ $t('loanDetails.investorPaymentsSchedule') }}</span>
          </v-tab>
          <v-tab>
            <span v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-bank-transfer-in</v-icon></span>
            <span v-else>{{ $t('loanDetails.investorsPayments') }}</span>
          </v-tab>
          <v-tab>
            <span v-if="$vuetify.breakpoint.mobile"><v-icon>mdi-file-document</v-icon></span>
            <span v-else>{{ $t('documents.titles.documents') }}</span>
          </v-tab>
          <slot name="tabs"/>
          <v-tabs-slider/>
        </v-tabs>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <slot name="details" :loan="loanData" :loading="loading">
          <v-list color="transparent">
            <v-row>
              <v-col cols="12" md="6" lg="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('loanDetails.loanIssueDate') }}</v-list-item-title>
                    <BaseSubtitle :loading="loading"><BaseDate :date="loanData.value_date" popup="left"/></BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('loanDetails.loanListedDate') }}</v-list-item-title>
                    <BaseSubtitle :loading="loading"><BaseDate :date="loanData.publishing_date" popup="left"/></BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('loanDetails.loanEndDate') }}</v-list-item-title>
                    <BaseSubtitle :loading="loading"><BaseDate :date="loanData.maturity_date"/></BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('loanDetails.loanStatus') }}</v-list-item-title>
                    <BaseSubtitle :loading="loading">{{ status }}</BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('loanDetails.loanTypePurpose') }}</v-list-item-title>
                    <BaseSubtitle :loading="loading">{{ loanType }}</BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('loanDetails.isScheduleExtendable') }}
                      <v-tooltip slot="append-outer" bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('loanDetails.isScheduleExtendableDescription') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <BaseSubtitle :loading="loading">{{ loanData.schedule_extendable? $t('loanDetails.yes'): $t('loanDetails.no') }}</BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('loanDetails.loanScheduleExtension') }}
                      <v-tooltip slot="append-outer" bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('loanDetails.loanScheduleExtensionDescription') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <BaseSubtitle :loading="loading">{{ loanData.is_schedule_extended? $t('loanDetails.yes'): $t('loanDetails.no') }}</BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('loanDetails.riskRating') }}
                      <v-tooltip slot="append-outer" bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('loanDetails.riskRatingDescription') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <BaseSubtitle :loading="loading">{{ loanData.risk_rating || $t('loanDetails.NA') }}</BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('loanDetails.buybackObligation') }}
                      <v-tooltip slot="append-outer" bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('loanDetails.buybackObligationDescription') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <BaseSubtitle :loading="loading">
                      <v-icon dense small :title="loanData.buyback_guarantee ? $t('loanDetails.buybackObligation') : $t('loanDetails.noBuybackObligation')">
                        {{ loanData.buyback_guarantee ? 'mdi-shield' : 'mdi-shield-off' }}
                      </v-icon>
                      <template v-if="loanData.buyback_guarantee && loanData.buyback_max_period">
                        ({{ loanData.buyback_max_period }} {{ $tc('day', loanData.buyback_max_period) }})
                      </template>
                    </BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('loanDetails.loanOriginatorSkinInTheGame') }}
                      <v-tooltip bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('loanDetails.loanOriginatorSkinInTheGameDescription') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <BaseSubtitle :loading="loading"><BasePercent :amount="loanData.skin_in_the_game"/></BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('loanDetails.borrowerAprcPercent') }}
                      <v-tooltip slot="append-outer" bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('loanDetails.borrowerAprcPercentDescription') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <BaseSubtitle :loading="loading"><BasePercent :amount="loanData.aprc"/></BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('loanDetails.borrowerInterestRatePercent') }}
                      <v-tooltip slot="append-outer" bottom max-width="400">
                        <template #activator="{ on }">
                          <v-icon slot="activator" dense color="primary" dark v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('loanDetails.borrowerInterestRatePercentDescription') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <BaseSubtitle :loading="loading"><BasePercent :amount="loanData.borrower_interest"/></BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('loanDetails.borrowerGender') }}</v-list-item-title>
                    <BaseSubtitle :loading="loading">{{ gender }}</BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('loanDetails.borrowerAge') }}</v-list-item-title>
                    <BaseSubtitle :loading="loading">{{ loanData.client_age || $t('loanDetails.NA') }}</BaseSubtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </slot>
      </v-tab-item>
      <v-tab-item>
        <slot name="borrowerPayments">
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th v-for="header in schedulePaymentsHeaders" :key="header.value" class="text-left">
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in interleavedPayments">
                  <!--<td v-for="header in schedulePaymentsHeaders" :key="header.value">
                    {{ item[header.value] }}
                  </td>-->
                  <template v-if="item.paid_date">
                    <tr :key="index" class="green--text font-weight-bold" :class="$vuetify.theme.dark ? 'blue-grey darken-4' : 'blue-grey lighten-5'">
                      <td>
                        <BaseDate :date="item.payment_date" popup="left"/>
                      </td>
                      <td/>
                      <td/>
                      <td/>
                      <td class="green--text">
                        <v-icon class="green--text mr-2">mdi-cash-check</v-icon>
                        <BaseMoney :amount="item.paid_amount"/>
                      </td>
                      <td v-if="statusColumn"/>
                    </tr>
                  </template>
                  <template v-else>
                    <tr :key="index">
                      <td>
                        <BaseDate :date="item.payment_date" popup="left"/>
                      </td>
                      <td>
                        <BaseMoney :amount="item.principal"/>
                      </td>
                      <td>
                        <BaseMoney :amount="item.interest"/>
                      </td>
                      <td>
                        <BaseMoney :amount="item.fees"/>
                      </td>
                      <td>
                        <BaseMoney :amount="item.total_amount"/>
                      </td>
                      <td v-if="statusColumn">
                        <template v-if="item.status && item.status == 'paid'">{{ $t('loanDetails.paid') }}</template>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </slot>
      </v-tab-item>
      <v-tab-item>
        <slot name="investorPaymentsSchedule">
          <v-data-table
            :headers="investorPaymentsHeaders"
            :items="investorPaymentsSchedule"
            :items-per-page="-1"
            hide-default-footer
          >
            <template #[`item.due_date`]="{ item }">
              <BaseDate :date="item.due_date" popup="left"/>
            </template>
            <template #[`item.amount`]="{ item }">
              <BaseMoney :amount="item.amount"/>
            </template>
            <template #[`item.paid_amount`]="{ item }">
              <BaseMoney :amount="item.paid_amount"/>
            </template>
            <template #[`item.type`]="{ item }">
              {{ $t(`loanDetails.${item.type}`) }}
            </template>
            <template #[`item.is_paid`]="{ item }">
              <BaseBoolean :value="item.is_paid"/>
            </template>
          </v-data-table>
        </slot>
      </v-tab-item>
      <v-tab-item>
        <slot name="investorsPayments">
          <v-data-table
            :headers="investmentsHeaders"
            :items="loanData.investments"
            :hide-default-footer="true"
            :items-per-page="-1"
            class="elevation-0"
          >
            <template #[`body.prepend`]="{}">
              <tr v-if="!loading">
                <th>{{ $t('loanDetails.skinInTheGame') }}</th>
                <td><BaseDate :date="loanData.publishing_date" popup="left"/></td>
                <td><BaseMoney :amount="loanData.amount * loanData.skin_in_the_game"/></td>
              </tr>
            </template>
            <template #[`item.number`]="{ item, index }">
              {{ $t('loanDetails.investorNum', { "number": index + 1 }) }}
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-chip
                    v-if="item.investment_account"
                    :color="item.investment_account == investmentAccount.id ? 'success' : 'orange darken-2'"
                    outlined
                    small
                    class="mx-1"
                    v-bind="attrs"
                    v-on="on"
                    v-text="$t('loanDetails.you')"
                  />
                </template>
                <span v-text="item.investment_account == investmentAccount.id
                  ? $t('loanDetails.yourInvestmentSameAccount')
                  : $t('loanDetails.yourInvestmentDifferentAccount')"
                />
              </v-tooltip>
              <v-chip
                v-if="!item.is_manual_investment"
                outlined
                small
                class="mx-1"
              >
                <v-icon small left>mdi-flash</v-icon>
                {{ $t('loanDetails.autoInvest') }}
              </v-chip>
            </template>
            <template #[`item.created_at`]="{ item }">
              <BaseDate :date="item.created_at" popup="left"/>
            </template>
            <template #[`item.amount`]="{ item }">
              <BaseMoney :amount="item.amount"/>
            </template>
          </v-data-table>
        </slot>
      </v-tab-item>
      <v-tab-item>
        <slot name="documents" :loan="loanData">
          <v-data-table
            :headers="documentListHeaders"
            :items="(loanData.loan_originator || {}).files"
            disable-pagination
            hide-default-footer
          >
            <template #[`item.actions`]="{ item }">
              <v-btn :href="item.file" download class="my-3" dark>
                {{ $t('loanDetails.download') }}
              </v-btn>
            </template>
          </v-data-table>
        </slot>
      </v-tab-item>
      <slot name="tab-items"/>
    </v-tabs-items>
  </v-card>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: "LoanDetail",
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tab: 0,
      loanData: {},
      loanClaims: [],
      loading: false,
    }
  },
  computed: {
    status() {
      return this.loanData.sub_status
        ? this.$t(`loanDetails.statuses.${this.loanData.sub_status}`)
        : this.$t('loanDetails.NA')
    },
    loanType() {
      return this.loanData.loan_type
        ? this.$t(`loanDetails.loanTypes.${this.loanData.loan_type}`)
        : this.$t('loanDetails.NA')
    },
    gender() {
      return this.loanData.client_gender
        ? this.$t(`loanDetails.gender.${this.loanData.client_gender}`)
        : this.$t('loanDetails.NA')
    },
    investmentsHeaders() {
      return [
        { text: '#',                value: 'number',           sortable: false },
        { text: this.$t('tableHeaders.date'),    value: 'created_at',       sortable: false },
        { text: this.$t('tableHeaders.amount'),  value: 'amount',           sortable: false },
      ]
    },
    statusColumn() {
      return this.loanData?.scheduled_payments?.some(v => !!v.status) || false
    },
    schedulePaymentsHeaders() {
      const headers = [
        { text: this.$t('loanDetails.paymentDate'),  value: 'payment_date',     sortable: false },
        { text: this.$t('loanDetails.principal'),    value: 'principal',        sortable: false },
        { text: this.$t('loanDetails.interest'),     value: 'interest',         sortable: false },
        { text: this.$t('loanDetails.otherFees'),    value: 'fees',             sortable: false },
        { text: this.$t('tableHeaders.total'),       value: 'total_amount',     sortable: false },
      ]
      if (this.statusColumn) {
        headers.push({
          text: this.$t('tableHeaders.status'),      value: 'status',           sortable: false,
        })
      }
      return headers
    },
    investorPaymentsHeaders() {
      return [
        { text: this.$t('loanDetails.paymentDate'),  value: 'due_date',         sortable: false },
        { text: this.$t('tableHeaders.amount'),      value: 'amount',           sortable: false },
        { text: this.$t('tableHeaders.paidAmount'),  value: 'paid_amount',      sortable: false },
        { text: this.$t('tableHeaders.type'),        value: 'type',             sortable: false },
        { text: this.$t('tableHeaders.fully_paid'),  value: 'is_paid',          sortable: false },
      ]
    },
    paymentsHeaders() {
      return [
        { text: this.$t('tableHeaders.date'),        value: 'paid_date',        sortable: false },
        { text: this.$t('tableHeaders.amount'),      value: 'paid_amount',      sortable: false },
      ]
    },
    documentListHeaders() {
      return [
        {value: 'name',     text: this.$t('tableHeaders.name'),   align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
        {value: 'actions',  text: '',                             align: "end",   class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
      ]
    },
    documentTypes() {
      return {
        'autoinvest_agreement': this.$t('documents.titles.autoinvestAgreement'),
        'claim_assignment':     this.$t('documents.titles.claimAssignment'    ),
        'claim_reassignment':   this.$t('documents.titles.claimReassignment'  ),
      }
    },
    interleavedPayments() {
      const payments = [
        ...(this.loanData?.payments           || []).map(x => ({...x, payment_date: x.paid_date})),
        ...(this.loanData?.scheduled_payments || []),
      ]
      payments.sort((p1, p2) => {
        if (p1.payment_date < p2.payment_date) { return -1 }
        if (p1.payment_date > p2.payment_date) { return +1 }
        return 0
      })
      return payments
    },
    investmentAccount() {
      return this.$store.state.account
    },
    investorPaymentsSchedule() {
      let payments = this.loanClaims || []
      // In case of a buyback, old claims are marked as inactive,
      // thus leaving us with already paid and newly created during buyback action
      payments = payments.filter(p => p.is_active || p.paid_amount > 0)
      // TODO: also sort in an principal -> interest pairs
      return payments
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      Promise.all([
        this.$http.get(`/loans/${this.loan.id}/`)
          .then((response) => {
            this.loading = false
            this.loanData = response.data
          })
          .catch(() => {
            this.loading = false
          }),
        this.$http.get(`/loan_claims/?loan=${this.loan.id}&investment__investment_account=${this.investmentAccount.id}`)
          .then((response) => {
            this.loading = false
            this.loanClaims = response.data.results
          })
          .catch(() => {
            this.loading = false
          }),
      ])
    },
  },
})
</script>
<style>
.loan-details .v-toolbar__content {
  padding: 0;
}

.v-data-table__expanded__content > td {
  max-width: calc(100vw - 64px)
}
</style>
